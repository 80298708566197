<template>
  <b-button
    :variant="variant"
    @click="$emit('click')"
    :class="{ 'soft-disabled': isDisabled, small: size === 'small' }"
  >
    <slot name="prefixIcon" />
    <span>{{ title }}</span>
    <slot name="suffixIcon" />
  </b-button>
</template>

<script>
export default {
  name: 'AddButton',
  props: {
    title: String,
    variant: {
      type: String,
      default: 'primary',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'default',
    },
  },
};
</script>
